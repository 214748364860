.templatesModal {
    max-width: 900px !important;
    width: 100% !important;
}

.gridContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    place-items: center;
    height: 500px;
}

.gridItem {
    border: 1px solid black;
    cursor: pointer;
}

.gridItemVertical {
    width: 150px;
    height: 200px;
}

.gridItemHorizontal {
    width: 200px;
    height: 150px;
}

.gridItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gridItemDisabled {
    position: relative;
}

.gridItemDisabled::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); 
    cursor: not-allowed;
}

.modalHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 0;
}

.modalHeaderContainer select {
    width: 20%;
}

.modalHeaderContainer > div:first-child {
    padding: 0;
}

.btnClose {
    all: unset !important;
    color: black !important;
    cursor: pointer !important;
    margin-right: 10px !important;
}

.btnClose:hover {
    background: none !important;
    color: black !important;
}
