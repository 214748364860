.displayBox {
    border-top: 1px solid #e7e9eb;
    border-bottom: 1px solid #e7e9eb;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
}

.displayData {
    color: #727A80;
}
