.imageCarousel {
    background: black;
}

.imageItem {
    width: 150px !important;
    height: 150px !important;
    padding: 5px !important;
    display: block !important;
    margin-right: 0 !important;
    cursor: pointer !important;
}

.imageItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: auto !important;
}
