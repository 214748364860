.cellWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .photoCell {
        width: 50px;
        height: 40px;
        overflow: hidden;

        .imgCover {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}