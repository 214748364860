.modalWrapper {
    display: block;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
}


.modal {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #FFF;
    padding: 1.25rem;
    z-index: 2000;
    width: 500px;
    animation: moveFromTop 0.3s ease-out;
    border-radius: calc(0.3rem - 1px);

    @media screen and (max-width: 576px) {
        width: 97%;
        height: auto;
        top: 1%;        
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 2000;
}

.closeButton {
    position: absolute;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    top: 25px;
    right: 1.25rem;
    background: unset;

    &:hover {
        opacity: 0.75;
    }
}

@keyframes moveFromTop {
    from {top: 0px;}
    to {top: 3%;}
}