.tui-image-editor-main-container {
    display: flex !important;
    flex-direction: column;
    border: none !important;
    width: 100% !important;
}

.tui-image-editor-container {
    background-color: transparent !important;
    width: 100% !important;
}

.tui-image-editor-help-menu,
.top {
    display: none !important;
}

.tui-image-editor-header {
    display: none !important;
}

.tui-image-editor-controls {
    display: flex !important;
    background-color: #212529 !important;
}

.tui-image-editor-menu {
    all: unset !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.tui-image-editor-main {
    all: unset !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    margin-top: 10px !important;
}

/* pic is inside this */
.tui-image-editor-wrap {
    all: unset !important;
    order: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 450px !important;
    padding: 20px 0 !important;
    background-color: #212529 !important;
}

/* pic is inside this */
.tui-image-editor-size-wrap {
    all: unset !important;
}

/* pic is inside this */
.tui-image-editor {
    top: 0 !important;
    display: flex !important;
    place-items: center !important;
}

.tui-image-editor-submenu {
    order: 2 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}

.tui-image-editor-submenu div {
    padding: 0 !important;
}

.tui-image-editor-submenu-item {
    text-align: center !important;
}

.tui-image-editor-submenu-item label {
    color: black !important;
    font-weight: 200 !important;
}

.tui-image-editor-button label {
    color: #8a8a8a !important;
}

.tie-crop-preset-button {
    width: 100% !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.tie-crop-preset-button div {
    margin: 0 !important;
    text-align: center !important;
    padding: 0 5px !important;
    /* width: 65px !important; */
}

.preset-3-2,
.preset-5-4,
.preset-7-5 {
    display: none !important;
}

.preset-new {
    display: inline-flex !important;
    flex-direction: column !important;
    align-items: center !important;
    cursor: pointer !important;
    color: #8a8a8a;
}

.preset-new span {
    font-size: 11px;
}

.preset-new svg {
    color: #8a8a8a;
}

.preset-new:hover svg {
    color: #434343;
}

.action {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
}

.apply,
.cancel {
    display: inline-flex !important;
    align-items: center !important;
}

.apply label,
.cancel label {
    cursor: pointer !important;
}

.apply:hover label {
    color: green !important;
}

.cancel:hover label {
    color: red !important;
}

.undo {
    padding: 5px 8px !important;
    border-radius: 3px !important;
    list-style-type: none;
    cursor: pointer;
}

.undo:hover {
    background-color: white !important;
}

.undo span {
    font-size: 11px;
    color: #8a8a8a;
    margin-left: 3px;
}

.free:hover label,
.line:hover label {
    color: #434343 !important;
}

.grid-dimensions-display {
    width: 100%;
    text-align: center;
    margin-bottom: 10px !important;
}

.tui-image-editor-newline {
    position: relative !important;
}

.custom-tooltip {
    position: absolute;
    top: -15px;
    right: -40px;
    display: none;
    max-width: 150px;
    padding: 50px;
    border-radius: 5px;
    background: #434343;
    color: white;
    font-size: 10px;
    text-align: center;
    white-space: normal;
}

/* tooltip arrow */
/* .custom-tooltip::before {
    content: '';
    position: absolute;
    border-width: 8px;
    border-style: solid;
    border-color: #434343 transparent transparent transparent;
    bottom: -13px;
    left: 50%;
    transform: translateX(-50%);
} */
