.modalVertical {
    max-width: 800px !important;
    width: 100% !important;
}

.modalHorizontal {
    max-width: 1050px !important;
    width: 100% !important;
}

.modalHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 0;
}

.modalHeaderContainer > div:first-child {
    padding: 0;
}

.btnCloseContainer {
    display: flex;
    justify-content: flex-end;
}

.btnClose {
    all: unset !important;
    color: black !important;
    cursor: pointer !important;
    margin-right: 10px !important;
}

.btnClose:hover {
    background: none !important;
    color: black !important;
}
