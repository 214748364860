.loaderCenter {
    position: fixed; 
    top: 50%;
    left: 55%;
}

@media screen and (max-width: 1024px) {
    .loaderCenter {
        left: 50%;
    }
  }