.mainContent {
    background: linear-gradient(90deg, rgba(21,32,40,1) 0%, rgba(21,32,40,1) 18%, rgba(0,212,255,0) 20%);
}

.pageContentAdmin {
    display: flex;
    max-width: 90%;
    margin: 0 auto 0 auto;
    align-items: stretch;    
}

.sideMenu {
    align-items: start;
    width: 18%;
    margin-right: 20px;
    margin-top: 70px;
    padding-top: 20px;
    min-height: 100vh;
    background-color: #152028;

    a {
        color: white;
    }
    
}

.pageContentChildren {
    width: 82%;
    margin-top: 90px
}

@media screen and (max-width: 1024px) {
    .mainContent {
        background: none;
    }
    .sideMenu {
        width: 0;
    }
    .pageContentChildren {
        width: 100%;
    }
}