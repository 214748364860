.tableRow {
    display: grid;
    transition: 0.3s ease-in-out;
}

.tableCol{
    width: 100%;
    overflow: hidden;
}

.tableSideModal {
    background-color: white; 
    width: 100%;
}

.sideModalShow {
    display: block;
    background-color: white;
    width: 500px; 
    position: sticky;
    top: 130px; 
}