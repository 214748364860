.userCard {
    height: 95%!important;
    padding-bottom: 50px!important;
}

.cardCover {
    height: 140px;
}

.cardCover::before {
    opacity: 0.9!important;
}

.buttonContainer {
    position: absolute!important;
    bottom: 10px;
    left: 0;

}

.deleteUser {
    position: fixed;
}