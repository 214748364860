.tableCellTooltip {
    position: relative;
    height: 40px;
    display: flex;
    align-items: start;

    .hideText {
        width: 100%;
        position: absolute;
        overflow: hidden !important;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    
        &:hover {
            display: block;
            white-space: unset;
            text-overflow: unset;
            background-color: white;
            padding: 10px;
            border: solid 1px #ccc;
            z-index: 5;
            top: 0
        }
    }

    .hideTextSecondHalf {
        &:hover {
            top: auto;
            bottom: 0;
        }
    }
}




