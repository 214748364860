.watermarkContainer {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.imageWrapper {
    position: relative;
    border: 2px solid #999;
    overflow: hidden;
    width: 190px;
    height: 190px;
    border-radius: 20px;
}

.iconX {
    width: 13px;
    height: 13px;
    margin-top: -7px;
}

.closeButton {
    position: absolute;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    padding: 0;
    color: #000;
    border: 1px solid #999;
    border-radius: 50px;
    top: 0px;
    left: 170px;
    background-color: #fff;
    cursor: pointer;
}

.uploadButton {
    position: absolute;
    box-sizing: content-box;
    width: 40px;
    height: 40px;    
    color: #000;
    border: 1px solid #999;
    border-radius: 50px;
    top: 0px;
    left:0px;   
    background-color: white;
}

.iconUpload {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.inputImage {
    display: none!important;
}

.imageFitCover {
    object-fit: cover;
    width: 100%;
    padding: 5px;
}