.cardBody {
    padding: 25px;
    
    .formLabel {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 17px;
    }
    .formContent {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 42px;
    
        .formInput {
            width: 30%;
            margin-right: 3%;
            margin-bottom: 1%;
        }
    }
}
