.nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    ul {
        list-style-type: none;
      }
  }
  
  .navToggle,
  .navClose {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .navCta {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
  }

  .navMenu {
    position: fixed;
  }
  
  @media screen and (max-width: 1024px) {
    .navMenu {
      position: fixed;
      top: 0;
      right: -100%;
      background-color: #152028;
      width: 100%;
      height: 100%;
      padding: 6rem 0 0;
      transition: right 0.4s;
      z-index: 800;
    }
  }
  @media screen and (max-width: 300px) {
    .navMenu {
      padding: 4rem 1rem 2rem;
    }
  
    .navList {
      row-gap: 1rem;
    }
  
    .navCta {
      padding: 8px 16px;
      font-size: 12px;
      margin-top: 1rem;
      width: auto;
      white-space: nowrap;
    }
  }
  
  .navList {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  
  .navLink {
    transition: color 0.4s;    
  }

  .active {
    color: #878a99 !important;
  }
  
  .navClose {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  
  .showMenu {
    right: 0;
  }

  .navList {
    display: flex;
    flex-direction: column;
    column-gap: 2.5rem;
  }
  
  .navMenuMobile {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #152028;
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }

  @media screen and (min-width: 1024px) {
    .navList {
      flex-direction: column;
      column-gap: 2.5rem;
    }
  }